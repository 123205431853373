(function () {

    /**
     * одинаковая высота колонок
     */

    var columnHeight = {
        init: function () {
            var _this = this;
            _this.height($(".step__item"));
            _this.height($(".step-block__icon"));
            _this.height($(".about-block"));
            _this.height($(".about-block__subtitle"));

        },
        height: function (columns) {
            var tallestcolumn = 0;
            columns.each(
                function () {
                    var currentHeight = $(this).height();
                    if (currentHeight > tallestcolumn) {
                        tallestcolumn = currentHeight;
                    }
                }
            );
            columns.height(tallestcolumn);
        }
    };

    /**
     * Слидеры
     */

    var sliders = [];

    $('.swiper-container').each(function(index, element){
        $(this).addClass('s'+index);
        $(this).find('.swiper-button-next').addClass('r'+index);
        $(this).find('.swiper-button-prev').addClass('l'+index);
        $(this).find('.swiper-pagination').addClass('p'+index);
        var slider = new Swiper('.s'+index, {
            slidesPerView: 'auto',
            pagination: '.p'+index,
            paginationClickable: true,
            nextButton: '.r'+index,
            prevButton: '.l'+index,
            parallax: true,
            loop: true,
            speed: 1500,
            autoplay: 3000,
            autoplayDisableOnInteraction: false,
            onProgress: function(e){
                if(e.isBeginning){
                    $('.l'+index).hide();
                    $('.r'+index).show();
                } else if(e.isEnd){
                    $('.r'+index).hide();
                    $('.l'+index).show();
                } else {
                    $('.r'+index).show();
                    $('.l'+index).show();
                }
            }
        });
        sliders.push(slider);
    });

    columnHeight.init();

}());

/**
 * кнопка открытия верхнего меню
 */

$(".header__menu-btn").click(function() {
    $(this).toggleClass("close");
});

/**
 * прокрутка до якоря
 */

$("a.anchor").click(function() {
    $("html, body").animate({
        scrollTop: $($(this).attr("href")).offset().top-20 + "px"
    }, {
        duration: 1500,
        easing: "swing"
    });
    return false;
});

/**
 * счетчик акции
 */

function declOfNum(number, titles) {
    var cases = [2, 0, 1, 1, 1, 2];
    return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
}

function timeToEvent(eventDate) {
    var now = new Date(),
        output = '',
        days = Math.floor(Math.round(eventDate - now) / 86400000),
        hours = 24 - now.getHours() - 1,
        minutes = 60 - now.getMinutes() - 1,
        seconds = 60 - now.getSeconds() - 1;

    days = (days < 1) ? '0' + days : days;
    hours = (hours < 10) ? '0' + hours : hours;
    minutes = (minutes < 10) ? '0' + minutes : minutes;
    seconds = (seconds < 10) ? '0' + seconds : seconds;

    output += '<span class="count count_day">';
    output += '<span class="count__number">' + days + '</span>';
    output += '<span class="count__text">' + declOfNum(days, ['день', 'дня', 'дней']) + '</span>';
    output += '</span>';

    output += '<span class="count count_hour">';
    output += '<span class="count__number">' + hours + '</span>';
    output += '<span class="count__text">' + declOfNum(hours, ['час', 'часа', 'часов']) + '</span>';
    output += '</span>';

    output += '<span class="count count_minute">';
    output += '<span class="count__number">' + minutes + '</span>';
    output += '<span class="count__text">' + declOfNum(minutes, ['минута', 'минуты', 'минут']) + '</span>';
    output += '</span>';

    output += '<span class="count count_second">';
    output += '<span class="count__number">' + seconds + '</span>';
    output += '<span class="count__text">' + declOfNum(seconds, ['секунда', 'секунды', 'секунд']) + '</span>';
    output += '</span>';

    return output;
}


var $counter = $('.clock');
var now = new Date(),
    eventDate = new Date(2015, 11, 30);

if (now < eventDate) {
    window.setInterval(function () {
        $counter.html(timeToEvent(eventDate));
    }, 1000);
}

//validation
$('#cb_phone').mask('+7 (000) 000-00-00');


// Валидация формы заказа
$('.order-form').each(function() {  // attach to all form elements on page
    $(this).validate({       // initialize plugin on each form
        ignore: [],
        rules: {
            'order-shower_email': {
                required: true,
                email: true
            },
            'order-bath_email': {
                required: true,
                email: true
            },
            'order-furniture_email': {
                required: true,
                email: true
            },
            'order-radiator_email': {
                required: true,
                email: true
            },
            'order-toilet_email': {
                required: true,
                email: true
            }
        },
        showErrors: function(errorMap, errorList) {
            $.each(this.successList, function(index, value) {
                var element = $(value);
                element.data("title", "").removeClass("error");
                element.data("title", "").addClass("success");
                return $(value).popover("hide");
            });
            return $.each(errorList, function(index, value) {
                var element = $(value.element);
                var _popover;
                _popover = $(value.element).popover({
                    trigger: "manual",
                    placement: "bottom",
                    content: value.message,
                    template: "<div class=\"popover\"><div class=\"arrow\"></div><div class=\"popover-inner\"><div class=\"popover-content\"><p></p></div></div></div>"
                });
                _popover.data("bs.popover").options.content = value.message;
                element.data("title", "").removeClass("success");
                element.data("title", "").addClass("error");
                return $(value.element).popover("show");
            });
        },
        messages:{
            'order-shower_name':{
                required: "Введите Ваше имя",
                minlength: "В поле должно быть минимум 2 символа"
            },
            'order-shower_last-name': {
                required: "Введите Вашу фамилию",
                minlength: "В поле должно быть минимум 2 символа"
            },
            'order-shower_email': {
                required: "Поле не заполнено или заполнено не верно",
                email: 'Укажите верный адрес электронной почты'
            },
            'order-bath_name':{
                required: "Введите Ваше имя",
                minlength: "В поле должно быть минимум 2 символа"
            },
            'order-bath_last-name': {
                required: "Введите Вашу фамилию",
                minlength: "В поле должно быть минимум 2 символа"
            },
            'order-bath_email': {
                required: "Поле не заполнено или заполнено не верно",
                email: 'Укажите верный адрес электронной почты'
            },
            'order-furniture_name':{
                required: "Введите Ваше имя",
                minlength: "В поле должно быть минимум 2 символа"
            },
            'order-furniture_last-name': {
                required: "Введите Вашу фамилию",
                minlength: "В поле должно быть минимум 2 символа"
            },
            'order-furniture_email': {
                required: "Поле не заполнено или заполнено не верно",
                email: 'Укажите верный адрес электронной почты'
            },
            'order-radiator_name':{
                required: "Введите Ваше имя",
                minlength: "В поле должно быть минимум 2 символа"
            },
            'order-radiator_last-name': {
                required: "Введите Вашу фамилию",
                minlength: "В поле должно быть минимум 2 символа"
            },
            'order-radiator_email': {
                required: "Поле не заполнено или заполнено не верно",
                email: 'Укажите верный адрес электронной почты'
            },
            'order-toilet_name':{
                required: "Введите Ваше имя",
                minlength: "В поле должно быть минимум 2 символа"
            },
            'order-toilet_last-name': {
                required: "Введите Вашу фамилию",
                minlength: "В поле должно быть минимум 2 символа"
            },
            'order-toilet_email': {
                required: "Поле не заполнено или заполнено не верно",
                email: 'Укажите верный адрес электронной почты'
            }
        }
    });
});

$(".callback-form").validate({
    rules: {
        'cb_name': {
            required: true,
            minlength: 2
        },
        'cb_phone': {
            required: true
        }
    },
    showErrors: function(errorMap, errorList) {
        $.each(this.successList, function(index, value) {
            var element = $(value);
            element.data("title", "").removeClass("error");
            element.data("title", "").addClass("success");
            return $(value).popover("hide");
        });
        return $.each(errorList, function(index, value) {
            var element = $(value.element);
            var _popover;
            _popover = $(value.element).popover({
                trigger: "manual",
                placement: "bottom",
                content: value.message,
                template: "<div class=\"popover\"><div class=\"arrow\"></div><div class=\"popover-inner\"><div class=\"popover-content\"><p></p></div></div></div>"
            });
            _popover.data("bs.popover").options.content = value.message;
            element.data("title", "").removeClass("success");
            element.data("title", "").addClass("error");
            return $(value.element).popover("show");
        });
    },
    messages:{
        'cb_name':{
            required: "Введите Ваше имя",
            minlength: "В поле должно быть минимум 2 символа"
        },
        'cb_phone': {
            required: "Введите Ваш номер телефона"
        }
    }
});
